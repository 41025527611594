module stratos.common.services {

    export interface ISecurityService {
        getSamsLicenses(): ng.IPromise<any>;
        checkRolesUpdates(): ng.IPromise<any>;
        getAadTenant(aadUserName: string): ng.IPromise<any>;
        logOut(aadUserName: string): ng.IPromise<any>;
        getUser(aadUserName: string): models.user;
        updateUser(aadUserName: string, user: models.user): void;
        getRememberedAadUserName();
        updateRememberedAadUserName(aadUserName: string);
        getEulaDefinition(successCallback: Function, failureCallback: Function, allowFromCache?: boolean, deferred?: ng.IDeferred<any>): ng.IPromise<any>;
        setActiveTenant(aadUserName: string, tenant: models.tenant): ng.IPromise<any>;
        acceptEula(accountId: any, tenant: models.tenant, eulaVersion: any, successCallback: Function, failureCallback: Function): ng.IPromise<any>;
        apiTestCall(successCallback: Function, failureCallback: Function): ng.IPromise<any>;
        overrideADALLoginHint(urlNavigate: string, userName: string, adalAuthenticationService: adal.AuthenticationContext);
        getUserById(userId: string, tenantId: string): ng.IPromise<any>;
        getTenantMainGroup(): ng.IPromise<any>;
        getToken(): string;
        acquireToken(): ng.IPromise<string>;
        resetPassword(aadUserName: string, email: string): ng.IPromise<any>;
        getUserPermissions(aadUserName: string);
        checkPermission(aaduserName, appCode, appRoleCode): boolean;
        checkAccess(aaduserName, appCode): boolean;
    }

    export class securityService implements ISecurityService {
        private baseUrl: Array<string> = window.location.href.split("/", 3);
        private baseUrlNoPort: string = this.baseUrl[2].split(":", 2)[0];
        private serviceBase: string = this.baseUrl[0] + "//" + this.baseUrlNoPort + "/security/";

        static $inject = ['$http', '$q', '$log', 'localStorageService', 'cacheService', '$window', '$timeout', 'adalAuthenticationService'];
        constructor(private $http: ng.IHttpService,
            private $q: ng.IQService,
            private $log: ng.ILogService,
            private localStorageService: ng.local.storage.ILocalStorageService,
            private cacheService: common.services.ICacheService,
            private $window: ng.IWindowService,
            private $timeout: ng.ITimeoutService,
            private adalAuthenticationService: any//adal.AuthenticationContext
        ) {

            //this.getUser();
        }

        public apiTestCall = (successCallback: Function, failureCallback: Function): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.apiTestCall()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/HealthProbe';
            var requestParams = null;

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                    if (response.data.ErrorCode) {
                        _self.$log.error(serviceCallLogText, 'failure', response);
                        failureCallback(response);
                    }
                    else {
                        _self.$log.info(serviceCallLogText, 'success', response);

                        successCallback(response.data);
                    }
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);

                    failureCallback(reason);
                });

            return httpPromise;
        }

        public getUserById = (userId: string, tenantId: string): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.getUserById()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/GetUserById';
            var requestParams = {
                UserId: userId,
                TenantId: tenantId
            };

            var defer = _self.$q.defer<any>();

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                    if (response.data.ErrorCode) {
                        _self.$log.error(serviceCallLogText, 'failure', response);
                        
                        defer.reject(response);
                    }
                    else {
                        _self.$log.info(serviceCallLogText, 'success', response);

                        defer.resolve(response.data);
                    }
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);

                    defer.reject(reason);
                });

            return defer.promise;
        }


        public getTenantMainGroup = (): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.getTenantMainGroup()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/GetTenantMainGroup';
            var requestParams = {
            };

            var defer = _self.$q.defer<any>();

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                        if (response.data.ErrorCode) {
                            _self.$log.error(serviceCallLogText, 'failure', response);
                        
                            defer.reject(response);
                        }
                        else {
                            _self.$log.info(serviceCallLogText, 'success', response);

                            defer.resolve(response.data);
                        }
                    },
                    function (reason) {
                        _self.$log.error(serviceCallLogText, 'failure', reason);

                        defer.reject(reason);
                    });

            return defer.promise;
        }

        public overrideADALLoginHint = (urlNavigate: string, userName: string, adalAuthenticationService: adal.AuthenticationContext) => {
            var _self = this;
            urlNavigate += '&login_hint=' + encodeURIComponent(userName);
            window.location.replace(urlNavigate);
        }

        public getSamsLicenses = (): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.getSamsLicenses()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/getSamsLicenses';
            var requestParams = null;

            var defer = _self.$q.defer<any>();

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                    if (response.data.ErrorCode) {
                        _self.$log.error(serviceCallLogText, 'failure', response);
                        
                        defer.reject(response);
                    }
                    else {
                        _self.$log.info(serviceCallLogText, 'success', response);

                        defer.resolve(response.data);
                    }
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);

                    defer.reject(reason);
                });

            return defer.promise;
        }

        public checkRolesUpdates = (): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.checkRolesUpdates()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/checkRolesUpdates';
            var requestParams = null;

            var defer = _self.$q.defer<any>();

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                    if (response.data.ErrorCode) {
                        _self.$log.error(serviceCallLogText, 'failure', response);
                        
                        defer.reject(response);
                    }
                    else {
                        _self.$log.info(serviceCallLogText, 'success', response);

                        defer.resolve(response.data);
                    }
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);

                    defer.reject(reason);
                });

            return defer.promise;
        }

        public getAadTenant = (aadUserName: string): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.getAadTenant()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/GetAadTenant';
            var requestParams = {
                AadUserName: aadUserName
            };

            var defer = _self.$q.defer<any>();

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                    if (response.data.ErrorCode) {
                        _self.$log.error(serviceCallLogText, 'failure', response);
                        
                        defer.reject(response);
                    }
                    else {
                        _self.$log.info(serviceCallLogText, 'success', response);

                        defer.resolve(response.data);
                    }
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);

                    defer.reject(reason);
                });

            return defer.promise;
        }

        
        public getEulaDefinition = (successCallback: Function, failureCallback: Function, allowFromCache = false, deferred?: ng.IDeferred<any>): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.getEulaDefinition()";
            _self.$log.info(serviceCallLogText);
            //deferred indicates that this is an internal call from login, which will only return when the deferred object is resolved/rejected, on internal callbacks above 

            var requestUrl = _self.serviceBase + 'api/security/getlatesteula';
            var requestParams = null;

            var httpPromise = _self.cacheService.resolveCache(allowFromCache, requestUrl, requestParams, serviceCallLogText, successCallback) ||
                _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                    if (response.data.ErrorCode) {
                        _self.$log.error(serviceCallLogText, 'failure', response);
                        failureCallback(response);
                    }
                    else {
                        _self.$log.info(serviceCallLogText, 'success', response);
                        if (deferred) {
                            successCallback(response.data, deferred)
                        }
                        else {
                            var result = response.data;
                            var cacheData = new common.models.cacheData(requestUrl, requestParams, result)
                            _self.cacheService.saveCache(cacheData);

                            successCallback(result);
                        }
                    }
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);

                    if (deferred) {
                        failureCallback(reason, deferred);
                    }
                    else {
                        failureCallback(reason);                        
                    }                    
                });

            return deferred ? deferred.promise : httpPromise;
        }

        public setActiveTenant = (aadUserName: string, tenant: models.tenant): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.setActiveTenant()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/setActiveTenant';
            var requestParams = {
                TenantId : tenant.TenantId
            };

            var defer = _self.$q.defer<any>();

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                    if (response.data.ErrorCode) {
                        _self.$log.error(serviceCallLogText, 'failure', response);
                        defer.reject(response);                        
                    }
                    else {

                        defer.resolve(response.data);
                    }
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);
                    defer.reject(reason);
                });

            return defer.promise;
        }

        public resetPassword = (aadUserName: string, email: string) => {
            var _self = this;
            var serviceCallLogText = "securityService.resetPassword()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/ResetAadPassword';
            var requestParams = {
                AadUserName: aadUserName,
                SamsUserName: email
            };

            var defer = _self.$q.defer<any>();

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                        if (response.data.ErrorCode) {
                            _self.$log.error(serviceCallLogText, 'failure', response);
                            defer.reject(response);                        
                        }
                        else {
                            defer.resolve(response.data);
                        }
                    },
                    function (reason) {
                        _self.$log.error(serviceCallLogText, 'failure', reason);
                        defer.reject(reason);
                    });

            return defer.promise;
        }

        public acceptEula = (accountId: any, tenant: models.tenant, eulaVersion: any, successCallback: Function, failureCallback: Function): ng.IPromise<any> => {
            var _self = this;
            var serviceCallLogText = "securityService.acceptEula()";
            _self.$log.info(serviceCallLogText);

            var requestUrl = _self.serviceBase + 'api/security/accepteula';
            var requestParams = {
                AccountId : accountId,
                TenantId : tenant.TenantId,
                EulaVersion: eulaVersion
            };

            var eulaDeffered = _self.$q.defer();

            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response: ng.IHttpPromiseCallbackArg<any>) {
                    _self.$log.info(serviceCallLogText, 'success', response);

                    if (response.data.ErrorCode) {
                        _self.$log.error(serviceCallLogText, 'failure', response);
                        eulaDeffered.reject(response);
                        failureCallback(response);
                    }
                    else {
                            eulaDeffered.resolve(response.data);
                            successCallback(response.data);                            
                    }
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);
                    eulaDeffered.reject(reason);
                    failureCallback(reason);
                });            
            return eulaDeffered.promise;
        }

        
        public logOut = (aadUserName): ng.IPromise<any> => {

            var _self = this;
            var serviceCallLogText = "securityService.logOut()";
            _self.$log.info(serviceCallLogText);
            
            var requestUrl = _self.serviceBase + 'api/security/LogOut';
            var requestParams = null;
            
            var httpPromise = _self.$http.post(requestUrl, requestParams)
                .then(function (response) {
                    _self.$log.info(serviceCallLogText, 'success', response);
                },
                function (reason) {
                    _self.$log.error(serviceCallLogText, 'failure', reason);
                }).finally(function () {        
                    var user = _self.getUser(aadUserName);
            
                    if (!user.RememberMe) {
                        user = new models.user();
                        _self.updateRememberedAadUserName(null);
                    }
                    
                    _self.updateUser(aadUserName, user);
                });

            return httpPromise;
        }


        public getUser = (aadUserName): models.user => {
            this.$log.info("==> securityService.getUser()"); 

            var user = new models.user();            

            var userData = this.localStorageService.get<any>('userData');

            if(userData && aadUserName)
            {
                user = userData[aadUserName];
            }

            user = user || new models.user();

            return user;
        }

        public updateUser = (aadUserName: string, user: models.user): void => {
            this.$log.info("==> securityService.updateUser()");

            var userData = this.localStorageService.get<any>('userData') || {};

            userData[aadUserName] = user;

            this.localStorageService.set('userData', userData);
        }

        public getRememberedAadUserName = () => {
            var aadUserName = this.localStorageService.get<any>('rememberedAadUserName');
            return aadUserName;
        };

        public updateRememberedAadUserName = (aadUserName) => {
            this.localStorageService.set('rememberedAadUserName', aadUserName);
        };
        
        public getToken = (): any => {
            //gets token from cache
            var jsonValue = sessionStorage.getItem("NeuroverseAadApps");
            var aadApps: Array<common.models.AadApp> = JSON.parse(jsonValue);
            var neuroverseApp = aadApps.filter((a) => { return a.AppName == 'Neuroverse' });
            var clientId = neuroverseApp[0].AppId;

            var token = this.adalAuthenticationService.getCachedToken(clientId);
            return token;
        }

        public acquireToken = () => {
            //gets token from adal logic, which will renew the token if needed before returning it
            var jsonValue = sessionStorage.getItem("NeuroverseAadApps");
            var aadApps: Array<common.models.AadApp> = JSON.parse(jsonValue);
            var neuroverseApp = aadApps.filter((a) => { return a.AppName == 'Neuroverse' });
            var clientId = neuroverseApp[0].AppId;

            var defer = this.$q.defer<any>();

            //typing definitions are outdated, cast to any for now
            var promise = (this.adalAuthenticationService.acquireToken(clientId, null) as any).then((token) => {
                console.log('==> adal.acquireToken', token);
                defer.resolve(token);

            }, (error) => defer.reject(error));

            return defer.promise;
        }

        public getUserPermissions = (aadUserName) => {

            var user = this.getUser(aadUserName);
            var appRoles = user.UiActiveTenant.AppRoles;
            
            var permissions = [];

            if(appRoles)
            {
                permissions = appRoles.map((appRole) => {
                    var split = appRole.split('/');
                    return { appCode: split[1], appRoleCode: split[2] };
                });
            }

            return permissions;
        }

        public checkPermission = (aadUserName, appCode, appRoleCode): boolean => {
            var permissions = this.getUserPermissions(aadUserName);
            
            var permission = permissions.filter((p) => { return p.appCode.toLocaleUpperCase() == appCode.toLocaleUpperCase() && p.appRoleCode == appRoleCode });

            return permission != null && permission.length > 0;
        }

        public checkAccess = (aadUserName, appCode): boolean => {
            var permissions = this.getUserPermissions(aadUserName);

            var permission = permissions.filter((p) => { return p.appCode.toLocaleUpperCase() == appCode.toLocaleUpperCase() && p.appRoleCode == common.constants.AppRoles.AllApps.Security.Access.AppRoleCode });

            return permission != null && permission.length > 0;
        }
    }

    var app = angular.module('stratos.common');
    app.service('securityService', securityService);
}